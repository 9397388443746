import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Project from './project';

const Projects = ({ data: projects }) => {
  if (projects.length === 0) {
    return null;
  }

  const [showMore, setShowMore] = useState(false);

  const toggleHandler = useCallback(() => setShowMore(!showMore), [showMore]);

  const GRID_LIMIT = 6;
  const firstSix = projects.slice(0, GRID_LIMIT);
  const otherProjects = projects.slice(GRID_LIMIT);

  return (
    <section className="c-section c-projects" id="other-projects">
      <h3 className="c-projects__title">Autres projets</h3>
      <div className="c-projects__grid">
        {firstSix.map(({ node }) => (
          <Project key={node.id} data={node} />
        ))}
        <TransitionGroup component={null}>
          {showMore &&
            otherProjects &&
            otherProjects.map(({ node }, i) => (
              <CSSTransition
                key={node.id}
                classNames="fadeup"
                timeout={i * 100 + 300}
                exit={false}
              >
                <Project data={node} transitionDelay={i * 100} />
              </CSSTransition>
            ))}
        </TransitionGroup>
      </div>

      {otherProjects.length !== 0 && (
        <button
          className="c-projects__more c-button"
          type="button"
          onClick={toggleHandler}
        >
          {showMore ? 'Moins de ' : 'Plus de '} Projets
        </button>
      )}
    </section>
  );
};

Projects.propTypes = {
  data: PropTypes.array.isRequired,
};

export default memo(Projects);
