import React from 'react';
import { email } from '@config';
import WaitAndAnimate from './waitAndAnimate';

const Email = () => (
  <div className="c-email">
    <WaitAndAnimate delay={2000}>
      <div className="c-email__wrapper">
        <a className="c-email__link" href={`mailto:${email}`}>
          {email}
        </a>
      </div>
    </WaitAndAnimate>
  </div>
);

export default Email;
