import React, { useState, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import PropTypes from 'prop-types';
import sr from '@utils/sr';
import { srConfig } from '@config';

const Jobs = ({ data }) => {
  const [activeTabId, setActiveTabId] = useState(0);
  const revealContainer = useRef(null);
  useEffect(() => sr.reveal(revealContainer.current, srConfig()), []);

  return (
    <section className="c-jobs c-section" id="jobs" ref={revealContainer}>
      <h2 className="c-heading">Où j'ai travaillé</h2>
      <div className="c-jobs__tabs-container">
        <div
          className="c-jobs__tabs"
          role="tablist"
          data-active-index={activeTabId}
        >
          {data &&
            data.map(({ node }, i) => {
              const { company } = node.frontmatter;
              return (
                <button
                  key={i}
                  className={`c-jobs__tab${
                    activeTabId === i ? ' c-jobs__tab--active' : ''
                  }`}
                  onClick={() => setActiveTabId(i)}
                  role="tab"
                  aria-selected={activeTabId === i ? 'true' : 'false'}
                  aria-controls={`job-${i}`}
                  id={`tab-${i}`}
                  tabIndex={activeTabId === i ? '0' : '-1'}
                >
                  <span>{company}</span>
                </button>
              );
            })}
        </div>
        <div className="c-jobs__content-container">
          {data &&
            data.map(({ node }, i) => {
              const { frontmatter, body } = node;
              const { title, url, company, range } = frontmatter;
              return (
                <div
                  className={`c-jobs__content${
                    activeTabId === i ? ' c-jobs__content--active' : ''
                  }`}
                  key={i}
                  id={`job-${i}`}
                  role="tabpanel"
                  tabIndex="-1"
                  aria-labelledby={`job-title-${i}`}
                  aria-hidden={activeTabId !== i}
                >
                  <h3 id={`job-title-${i}`} className="c-jobs__title">
                    <span>{title}</span>
                    <span className="c-jobs__company">
                      <span>&nbsp;@&nbsp;</span>
                      <a
                        href={url}
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                      >
                        {company}
                      </a>
                    </span>
                  </h3>
                  <div className="c-jobs__range">
                    <span>{range}</span>
                  </div>
                  <MDXRenderer>{body}</MDXRenderer>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

Jobs.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Jobs;

export const query = graphql`
  fragment JobItem on Mdx {
    frontmatter {
      title
      company
      location
      range
      url
    }
    body
  }
`;
