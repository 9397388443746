import { graphql } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import sr from '@utils/sr';
import { srConfig, github } from '@config';

const About = ({ data }) => {
  const { frontmatter, body } = data[0].node;
  const { title, skills, avatar } = frontmatter;
  const image = getImage(avatar);
  const revealContainer = useRef(null);
  useEffect(() => sr.reveal(revealContainer.current, srConfig()), []);

  return (
    <section className="c-section" id="about" ref={revealContainer}>
      <h2 className="c-heading">{title}</h2>
      <div className="c-about__container">
        <div className="c-about__content">
          <MDXRenderer>{body}</MDXRenderer>
          <ul className="c-about__list">
            {skills &&
              skills.map((skill, i) => (
                <li className="c-about__item" key={i}>
                  {skill}
                </li>
              ))}
          </ul>
        </div>
        <a className="c-about__avatar-container c-blended" href={github}>
          <GatsbyImage
            className="c-about__avatar c-blended__image"
            image={image}
            alt="Avatar de Jordan-T"
            loading="lazy"
          />
        </a>
      </div>
    </section>
  );
};

About.propTypes = {
  data: PropTypes.array.isRequired,
};

export default About;

export const query = graphql`
  fragment AboutItem on Mdx {
    frontmatter {
      title
      avatar {
        childImageSharp {
          gatsbyImageData(
            width: 300
            quality: 90
            tracedSVGOptions: { color: "#64ffda" }
            placeholder: TRACED_SVG
            layout: CONSTRAINED
          )
        }
      }
      skills
    }
    body
  }
`;
