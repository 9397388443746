import { graphql } from 'gatsby';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { email } from '@config';
import { MemoTypical } from './memoTypical';
import WaitAndAnimate from './waitAndAnimate'; // use 'react-typical' when it's fixed

const Hero = ({ data }) => {
  const [TypicalMounted, setTypicalMounted] = useState(false);
  const [maxTitleLines, setMaxTitleLines] = useState(1);
  const elementRef = useRef(null);
  const titleRef = useRef(null);
  const { frontmatter, body } = data[0].node;
  const steps = (frontmatter.subtitles || []).reduce((acc, text) => {
    const number = Number(text);
    if (Number.isNaN(number) === false) {
      acc.splice(-1, 1, number);
      return acc;
    }
    acc.push(text);
    acc.push(2000 + 1000 * Math.random());
    return acc;
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => setTypicalMounted(true), 1000);
    return () => clearTimeout(timeout);
  }, []);

  // resize, set max lines
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    let timer = null;
    const usedText = steps.filter(step => Number.isNaN(Number(step)));

    const onResize = () => {
      if (titleRef !== null) {
        const p = document.createElement('p');
        titleRef.current.append(p);
        p.innerHTML = 'A';

        const baseHeight = p.getBoundingClientRect().height;
        let maxLines = 1;

        usedText.forEach(text => {
          p.innerHTML = text;
          const { height } = p.getBoundingClientRect();
          const lines = Math.round(height / baseHeight);
          maxLines = lines > maxLines ? lines : maxLines;
        });

        p.remove();
        setMaxTitleLines(maxLines);
      }
    };
    const debouncedResize = () => {
      clearTimeout(timer);
      timer = setTimeout(onResize, 100);
    };

    window.addEventListener('resize', debouncedResize, true);
    onResize();

    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', debouncedResize, true);
    };
  }, []);

  // scrollDown info (if needed)
  const [scrolled, setScrolled] = useState(true);
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (elementRef !== null) {
      const heroHeight = elementRef.current.getBoundingClientRect().height;
      if (Math.abs(heroHeight - document.documentElement.clientHeight) > 50) {
        return;
      }
    }

    const scrollY = window.scrollY;
    const checkScroll = () => {
      if (window.scrollY - scrollY > 50) {
        setScrolled(true);
        window.removeEventListener('scroll', checkScroll, true);
      }
    };

    setScrolled(false);
    window.addEventListener('scroll', checkScroll, true);

    return () => {
      window.removeEventListener('scroll', checkScroll, true);
    };
  }, []);

  const intro = <span className="c-hero__intro">{frontmatter.title}</span>;
  const name = <h1 className="c-hero__name">{frontmatter.name}.</h1>;
  const titleStyles =
    typeof window === 'undefined'
      ? undefined
      : { '--number-of-lines': maxTitleLines };

  const title = (
    <div className="c-hero__title" ref={titleRef} style={titleStyles}>
      {steps.length !== 0 && TypicalMounted ? (
        <MemoTypical steps={steps} loop={Infinity} />
      ) : (
        <p>{frontmatter.subtitle}</p>
      )}
    </div>
  );
  const content = (
    <div className="c-hero__content">
      <MDXRenderer>{body}</MDXRenderer>
    </div>
  );
  const link = (
    <div>
      <a className="c-button c-hero__button" href={`mailto:${email}`}>
        Contactez-moi
      </a>
    </div>
  );

  const items = [intro, name, title, content, link];

  return (
    <section
      ref={elementRef}
      className={`c-hero c-section${
        scrolled === true ? ' c-hero--scrolled' : ''
      }`}
    >
      {items.map((item, i) => (
        <WaitAndAnimate key={i} classNames="fadeup" delay={1000 + 100 * i}>
          {item}
        </WaitAndAnimate>
      ))}
      <WaitAndAnimate key={6} classNames="fade" delay={3000} hidden={false}>
        <span className="c-hero__scrollDown" />
      </WaitAndAnimate>
    </section>
  );
};

Hero.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Hero;

export const query = graphql`
  fragment Hero on Mdx {
    frontmatter {
      title
      name
      subtitle
      subtitles
      contactText
    }
    body
  }
`;
