module.exports = {
  siteTitle: 'Jordan Taisne | Développeur front-end',
  siteDescription:
    'Jordan Taisne, développeur front-end passionné basé sur Lille.',
  siteKeywords:
    'Jordan Taisne, Jordan, Taisne, Jordan-T, Développeur front-end, Développeur web, javascript, France',
  siteUrl: 'https://jordan.taisne.com',
  siteLanguage: 'fr_FR',
  googleAnalyticsID: process.env.GOOGLE_ANALYTICS_ID || '',
  googleVerification: process.env.GATSBY_GOOGLE_VERIFICATION || '',
  startUrl: process.env.START_URL || '/',
  name: 'Jordan Taisne',
  location: 'Lille, France',
  email: 'jordan.taisne@gmail.com',
  github: 'https://github.com/Jordan-T',
  gitlab: 'https://gitlab.com/Jordan-T',
  twitterHandle: '@Jordan_Taisne',
  socialMedia: [
    {
      name: 'Github',
      url: 'https://github.com/Jordan-T',
    },
    {
      name: 'Gitlab',
      url: 'https://gitlab.com/Jordan-T',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/jordantaisne59',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/Jordan_Taisne',
    },
  ],

  navLinks: [
    {
      name: 'À propos',
      url: '/#about',
    },
    {
      name: 'Expérience',
      url: '/#jobs',
    },
    {
      name: 'Travail',
      url: '/#projects',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,
  greenColor: '#64ffda',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
