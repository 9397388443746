import React, { memo } from 'react';
import Email from './email';
import Social from './social';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="c-footer">
      <Social />
      <Email />
      <div className="c-footer__copy">
        <p>
          Site crée à partir du site de{' '}
          <a
            className="c-footer__github-link"
            href="https://github.com/bchiang7/v4"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            Brittany Chiang
          </a>
        </p>
        <p>
          <span className="c-copy">&copy;</span>
          {`${year !== 2019 ? '2019-' : ''}${year}`} Jordan Taisne
        </p>
      </div>
    </footer>
  );
};

export default memo(Footer);
