// @TODO: remove scrollreveal
let ScrollReveal = null;
let loading = false;
const tempCalls = [];

const reveal = (el, config) => {
  if (ScrollReveal) {
    ScrollReveal.reveal(el, config);
  }
};

export default {
  reveal(el, config) {
    if (!ScrollReveal && typeof window !== 'undefined') {
      tempCalls.push({ el, config });

      if (!loading) {
        loading = true;
        import('scrollreveal').then(ScrollRevealImported => {
          ScrollReveal = ScrollRevealImported.default();

          tempCalls.forEach(({ el, config }) => {
            reveal(el, config);
          });
        });
      }
      return;
    }
    reveal(el, config);
  },
};
