import { graphql } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import sr from '@utils/sr';
import { srConfig } from '@config';
import { IconGithub, IconGitlab, IconExternal } from '@components/icons';

const Featured = ({ data }) => {
  const revealTitle = useRef(null);
  const revealProjects = useRef([]);
  useEffect(() => {
    sr.reveal(revealTitle.current, srConfig());
    revealProjects.current.forEach((ref, i) =>
      sr.reveal(ref, srConfig(i * 100)),
    );
  }, []);

  const featuredProjects = data.filter(
    ({ node }) => node.frontmatter.show === 'true',
  );

  return (
    <section className="c-section" id="projects">
      <h2 className="c-heading" ref={revealTitle}>
        Certaines choses que j'ai créées
      </h2>

      {featuredProjects &&
        featuredProjects.map(({ node }, i) => {
          const { id, frontmatter, body } = node;
          const { external, title, tech, github, gitlab, cover } = frontmatter;

          return (
            <div
              className="c-featured"
              key={id}
              ref={el => (revealProjects.current[i] = el)}
            >
              <div className="c-featured__content">
                <p className="c-featured__label">Projet en vedette</p>
                <h3 className="c-featured__title">
                  {external ? (
                    <a
                      href={external}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      aria-label="External Link"
                    >
                      {title}
                    </a>
                  ) : (
                    title
                  )}
                </h3>
                <div className="c-featured__description">
                  <MDXRenderer>{body}</MDXRenderer>
                </div>
                {tech && (
                  <ul className="c-featured__techs">
                    {tech.map((tech, i) => (
                      <li key={i}>{tech}</li>
                    ))}
                  </ul>
                )}
                <div className="c-featured__links">
                  {github && (
                    <a
                      href={github}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      aria-label="Github Link"
                    >
                      <IconGithub />
                    </a>
                  )}
                  {gitlab && (
                    <a
                      href={gitlab}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      aria-label="Gitlab Link"
                    >
                      <IconGitlab />
                    </a>
                  )}
                  {external && (
                    <a
                      href={external}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      aria-label="External Link"
                    >
                      <IconExternal />
                    </a>
                  )}
                </div>
              </div>

              <a
                className="c-featured__image-container c-blended"
                href={
                  external ? external : github ? github : gitlab ? gitlab : '#'
                }
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <GatsbyImage
                  className="c-featured__image c-blended__image"
                  image={cover.childImageSharp.gatsbyImageData}
                  alt={title}
                  loading="lazy"
                />
              </a>
            </div>
          );
        })}
    </section>
  );
};

Featured.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Featured;

export const query = graphql`
  fragment FeaturedItem on Mdx {
    id
    frontmatter {
      title
      cover {
        childImageSharp {
          gatsbyImageData(
            width: 580
            quality: 90
            tracedSVGOptions: { color: "#64ffda" }
            placeholder: TRACED_SVG
            layout: CONSTRAINED
          )
        }
      }
      tech
      github
      gitlab
      external
      show
    }
    body
  }
`;
