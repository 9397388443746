import React from 'react';
import { socialMedia } from '@config';
import { FormattedIcon } from '@components/icons';
import WaitAndAnimate from './waitAndAnimate';

const Social = () => (
  <div className="c-social">
    <WaitAndAnimate delay={2000}>
      <ul className="c-social__list">
        {socialMedia &&
          socialMedia.map(({ url, name }, i) => (
            <li key={i}>
              <a
                href={url}
                className="c-social__link"
                target="_blank"
                rel="nofollow noopener noreferrer"
                aria-label={name}
              >
                <FormattedIcon name={name} />
              </a>
            </li>
          ))}
      </ul>
    </WaitAndAnimate>
  </div>
);

export default Social;
