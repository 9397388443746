import React, { memo } from 'react';
import Typical from 'react-typical';

export const MemoTypical = ({ ...props }) => {
  function TypicalPropsAreEqual(prevProps, nextProps) {
    for (const attr of prevProps) {
      let same = true;

      if (attr === 'steps') {
        same = prevProps.steps.every((step, i) => step === nextProps.steps[i]);
      } else {
        same = prevProps[attr] === nextProps[attr];
      }

      if (same === false) {
        break;
      }
    }
  }

  const MemoTypical = memo(Typical, TypicalPropsAreEqual);

  return <MemoTypical {...props} />;
};
