import PropTypes from 'prop-types';
import React, { Component, memo } from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import { throttle } from '@utils';
import { navLinks, navHeight } from '@config';
import { IconLogo } from '@components/icons';
import WaitAndAnimate from './waitAndAnimate';

const DELTA = 5;

const InternalNav = ({ navLinks, menuOpen, toggleMenu }) => {
  const handleCloseNav = () => {
    if (menuOpen === true) {
      toggleMenu();
    }
  };

  return (
    <nav className="c-nav__nav">
      <WaitAndAnimate>
        <div className="c-nav__logo">
          <Link
            className="c-nav__logo-link"
            to="/"
            aria-label="home"
            onClick={handleCloseNav}
          >
            <IconLogo />
          </Link>
        </div>
      </WaitAndAnimate>

      <WaitAndAnimate delay={600}>
        <button
          className="c-nav__burger"
          type="button"
          onClick={toggleMenu}
          aria-label="Afficher/Masquer le menu"
        >
          <span className="c-nav__burger-box">
            <span className="c-nav__burger-content" />
          </span>
        </button>
      </WaitAndAnimate>

      <div className="c-nav__links">
        <ol className="c-nav__list">
          {navLinks &&
            navLinks.map(({ url, name }, i) => (
              <WaitAndAnimate
                key={i}
                classNames="fadedown"
                delay={150 + i * 100}
              >
                <li className="c-nav__item">
                  <Link
                    className="c-nav__link"
                    to={url}
                    onClick={handleCloseNav}
                  >
                    {name}
                  </Link>
                </li>
              </WaitAndAnimate>
            ))}
        </ol>

        <WaitAndAnimate
          classNames="fadedown"
          delay={150 + navLinks.length * 100}
        >
          <div>
            <a
              className="c-button c-button--small c-nav__resume-link"
              href="/cv.pdf"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              CV
            </a>
          </div>
        </WaitAndAnimate>
      </div>
    </nav>
  );
};

InternalNav.propTypes = {
  navLinks: PropTypes.array.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

const InternalNavMemo = memo(InternalNav);

class Nav extends Component {
  state = {
    menuOpen: false,
    scrollDirection: 'none',
    lastScrollTop: 0,
  };

  componentDidMount() {
    window.addEventListener('scroll', () => throttle(this.handleScroll()));
    window.addEventListener('resize', () => throttle(this.handleResize()));
    window.addEventListener('keydown', e => this.handleKeydown(e));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => this.handleScroll());
    window.removeEventListener('resize', () => this.handleResize());
    window.removeEventListener('keydown', e => this.handleKeydown(e));
  }

  toggleMenu = () => this.setState({ menuOpen: !this.state.menuOpen });

  handleScroll = () => {
    const { menuOpen, scrollDirection, lastScrollTop } = this.state;
    const fromTop = window.scrollY;

    // Make sure they scroll more than DELTA
    if (Math.abs(lastScrollTop - fromTop) <= DELTA || menuOpen) {
      return;
    }

    if (fromTop < DELTA) {
      this.setState({ scrollDirection: 'none' });
    } else if (fromTop > lastScrollTop && fromTop > navHeight) {
      if (scrollDirection !== 'down') {
        this.setState({ scrollDirection: 'down' });
      }
    } else if (fromTop + window.innerHeight < document.body.scrollHeight) {
      if (scrollDirection !== 'up') {
        this.setState({ scrollDirection: 'up' });
      }
    }

    this.setState({ lastScrollTop: fromTop });
  };

  handleResize = () => {
    if (window.innerWidth > 768 && this.state.menuOpen) {
      this.toggleMenu();
    }
  };

  handleKeydown = e => {
    if (!this.state.menuOpen) {
      return;
    }

    if (e.which === 27 || e.key === 'Escape') {
      this.toggleMenu();
    }
  };

  render() {
    const { menuOpen, scrollDirection } = this.state;

    const classNames = ['c-nav'];

    if (scrollDirection === 'up') {
      classNames.push('c-nav--scroll-up');
    }
    if (scrollDirection === 'down') {
      classNames.push('c-nav--scroll-down');
    }

    if (menuOpen === true) {
      classNames.push('c-nav--open');
    }

    return (
      <header className={classNames.join(' ')}>
        <Helmet>
          <body className={menuOpen ? 'blur' : ''} />
        </Helmet>

        <InternalNavMemo
          navLinks={navLinks}
          menuOpen={menuOpen}
          toggleMenu={this.toggleMenu}
        />
      </header>
    );
  }
}

export default Nav;
