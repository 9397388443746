import React, { useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import sr from '@utils/sr';
import { srConfig, email } from '@config';

const Contact = ({ data }) => {
  const { frontmatter, body } = data[0].node;
  const { title } = frontmatter;
  const revealContainer = useRef(null);
  useEffect(() => sr.reveal(revealContainer.current, srConfig()), []);

  return (
    <section className="c-contact c-section" id="contact" ref={revealContainer}>
      <p className="c-heading c-contact__heading">Et après ?</p>

      <h2 className="c-contact__title">{title}</h2>

      <MDXRenderer>{body}</MDXRenderer>

      <a
        className="c-button c-contact__link"
        href={`mailto:${email}`}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        Restons en contact
      </a>
    </section>
  );
};

Contact.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Contact;

export const query = graphql`
  fragment ContactItem on Mdx {
    frontmatter {
      title
    }
    body
  }
`;
