import { graphql } from 'gatsby';
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import {
  IconGithub,
  IconGitlab,
  IconExternal,
  IconFolder,
} from '@components/icons';

const Project = ({ data, transitionDelay = 0 }) => {
  const [focused, setFocused] = useState(false);
  const { frontmatter, body } = data;
  const { github, gitlab, external, title, tech } = frontmatter;
  const style = transitionDelay
    ? { transitionDelay: `${transitionDelay}ms` }
    : undefined;

  const links = [];
  if (github !== '') {
    links.push({
      title: 'Github Link',
      link: github,
      Icon: IconGithub,
    });
  }
  if (gitlab !== '') {
    links.push({
      title: 'Gitlab Link',
      link: gitlab,
      Icon: IconGitlab,
    });
  }
  if (external !== '') {
    links.push({
      title: 'External Link',
      link: external,
      Icon: IconExternal,
    });
  }

  return (
    <div
      className={`c-project${focused ? ' c-project--focused' : ''}`}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      style={style}
    >
      <div className="c-project__inner">
        <div className="c-project__header">
          <div className="c-project__folder">
            <IconFolder />
          </div>
          {links.length !== 0 && (
            <div className="c-project__links">
              {links.map(({ title, link, Icon }, i) => (
                <a
                  key={i}
                  className="c-project__icon-link"
                  href={link}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  aria-label={title}
                >
                  <Icon />
                </a>
              ))}
            </div>
          )}
        </div>
        <h4 className="c-project__name">{title}</h4>
        <div className="c-project__description">
          <MDXRenderer>{body}</MDXRenderer>
        </div>
        <ul className="c-project__techs">
          {tech.map((tech, i) => (
            <li key={i}>{tech}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

Project.propTypes = {
  data: PropTypes.object.isRequired,
  transitionDelay: PropTypes.number,
};

export default memo(Project);

export const query = graphql`
  fragment ProjectItem on Mdx {
    id
    frontmatter {
      title
      tech
      github
      gitlab
      external
    }
    body
  }
`;
