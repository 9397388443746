import PropTypes from 'prop-types';
import React, {
  cloneElement,
  memo,
  useEffect,
  useState,
  Children,
} from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

/**
 *
 * @param {object} props
 * @param props.children
 * @param {string} props.classNames
 * @param {delay} props.delay Delay in ms
 * @param {boolean} [props.hidden=true] true if the element is cloned & hidden before show
 * @returns {React.DetailedReactHTMLElement<{className: string}, HTMLElement>|*}
 * @constructor
 */
const WaitAndAnimate = ({
  children,
  classNames = 'fade',
  delay = 0,
  hidden = true,
}) => {
  const [waiting, setWaiting] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setWaiting(false);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (waiting === true && hidden === false) {
    return null;
  }

  if (waiting === true) {
    let child = children;
    if (typeof children === 'function') {
      child = children();
    }
    if (Children.count(child) === 0) {
      console.error('REQUIRE 1 ELEMENT', child);
      return child;
    }

    let className = 'js-hidden';

    if (
      child.props !== undefined &&
      child.props !== null &&
      child.props.className
    ) {
      className += ` ${child.props.className}`;
    }

    return cloneElement(child, { className });
  }

  return (
    <TransitionGroup component={null}>
      <CSSTransition classNames={classNames} timeout={3000} appear>
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
};

WaitAndAnimate.propTypes = {
  children: PropTypes.any,
  classNames: PropTypes.string,
  delay: PropTypes.number,
  hidden: PropTypes.bool,
};

export default memo(WaitAndAnimate);
