import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import config from '@config';
// import favicon from '@images/favicons/favicon.ico';
import ogImage from '@images/jordan-t.jpg';

const Head = ({ metadata }) => (
  <Helmet>
    <html
      lang="fr"
      prefix="og: http://ogp.me/ns#"
      className={typeof window === 'undefined' ? 'no-js' : 'js'}
    />
    <title itemProp="name" lang="fr">
      {metadata.title}
    </title>
    {/*<link rel="shortcut icon" href={favicon} />*/}
    <meta name="description" content={metadata.description} />
    <meta name="keywords" content={config.siteKeywords} />

    <link rel="dns-prefetch" href="https://www.google-analytics.com" />

    <meta name="google-site-verification" content={config.googleVerification} />
    <meta property="og:title" content={metadata.title} />
    <meta property="og:description" content={metadata.description} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={metadata.siteUrl} />
    <meta property="og:site_name" content={metadata.title} />
    <meta property="og:image" content={`${config.siteUrl}${ogImage}`} />
    <meta property="og:image:width" content="1024" />
    <meta property="og:image:height" content="1024" />
    <meta property="og:image:type" content="image/jpeg" />
    <meta property="og:locale" content={config.siteLanguage} />
    <meta itemProp="name" content={metadata.title} />
    <meta itemProp="description" content={metadata.description} />
    <meta itemProp="image" content={`${config.siteUrl}${ogImage}`} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:url" content={metadata.siteUrl} />
    <meta name="twitter:site" content={config.twitterHandle} />
    <meta name="twitter:creator" content={config.twitterHandle} />
    <meta name="twitter:title" content={metadata.title} />
    <meta name="twitter:description" content={metadata.description} />
    <meta name="twitter:image" content={`${config.siteUrl}${ogImage}`} />
    <meta name="twitter:image:alt" content={metadata.title} />
  </Helmet>
);

export default Head;

Head.propTypes = {
  metadata: PropTypes.object.isRequired,
};
